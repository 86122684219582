import React from 'react'
import {FlatList, StyleSheet, View} from 'react-native'
import {useFocusEffect} from '@react-navigation/native'
import {NativeStackScreenProps, CommonNavigatorParams} from 'lib/routes/types'
import {ViewHeader} from '../com/util/ViewHeader'
import {Text} from 'view/com/util/text/Text'
import {CenteredView} from 'view/com/util/Views'
import {usePalette} from 'lib/hooks/usePalette'
import {s} from 'lib/styles'
import {useSetMinimalShellMode} from '#/state/shell'
import {Trans, msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'

const items = [
  {
    category: 'UI設定',
    label: 'UI設定',
    subItems: [
      {label: 'shinyテーマ設定'},
      {label: 'トップへ移動するボタンの表示設定'},
      {label: 'いいねボタンのアイコン設定（❤️ or ⭐️）'},
      {label: 'リポストといいねの数の表示設定'},
    ],
  },
  {
    category: 'Post',
    label: 'Post',
    subItems: [{label: '下書き機能'}, {label: '特殊フォント編集機能'}],
  },
  {
    category: 'ショートカットキー',
    label: 'ショートカットキー',
    subItems: [{label: '「N」新規投稿'}],
  },
]

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 50,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  bulletPoint: {
    fontSize: 20,
    paddingRight: 10,
  },
  itemText: {
    fontSize: 16,
  },
  category: {
    paddingVertical: 10,
  },
  categoryText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
})

type Props = NativeStackScreenProps<CommonNavigatorParams, 'Releases'>

export const ReleasesScreen = (_props: Props) => {
  const pal = usePalette('default')
  const setMinimalShellMode = useSetMinimalShellMode()
  const {_} = useLingui()

  useFocusEffect(
    React.useCallback(() => {
      setMinimalShellMode(false)
    }, [setMinimalShellMode]),
  )

  const renderSubItem = ({item}) => (
    <View style={styles.item}>
      <Text style={styles.bulletPoint}>•</Text>
      <Text style={styles.itemText}>{item.label}</Text>
    </View>
  )

  const renderItem = ({item}) => (
    <View>
      {item.category ? (
        <View style={styles.category}>
          <Text style={styles.categoryText}>{item.category}</Text>
          <FlatList
            data={item.subItems}
            renderItem={renderSubItem}
            keyExtractor={(subItem, index) => subItem.label + index}
          />
        </View>
      ) : (
        <View style={styles.item}>
          <Text style={styles.bulletPoint}>•</Text>
          <Text style={styles.itemText}>{item.label}</Text>
        </View>
      )}
    </View>
  )

  return (
    <View>
      <ViewHeader title={_(msg`Release Notes`)} />
      <CenteredView style={{paddingBottom: 32}}>
        <Text type="title-xl" style={[pal.text, s.p20, s.pb5]}>
          <Trans>Release Notes</Trans>
        </Text>
        <View style={[pal.text, s.p20]}>
          <FlatList
            data={items}
            renderItem={renderItem}
            keyExtractor={(item, index) =>
              (item.label ?? item.category ?? '') + index
            }
          />
        </View>
      </CenteredView>
    </View>
  )
}
